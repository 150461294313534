import i18n from 'i18next';
import { initReactI18next, TFunction } from 'react-i18next';
import { setLocale } from 'yup';

import en from 'src/locales/en.json';
import ko from 'src/locales/ko.json';

export const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
} as const;

export function buildYupLocale(_: unknown, t): void {
  setLocale({
    mixed: {
      required: ({ path }) => {
        return t('validated.required', { name: t(`label.${path}`) });
      },
      oneOf: ({ path }) => {
        return t('validated.not_matched', { name: t(`label.${path}`) });
      },
      notType: ({ path, type }) => {
        switch (type) {
          case 'number':
            return t('validated.numeric', { name: t(`label.${path}`) });
          default:
            return t('validated.not_matched', { name: t(`label.${path}`) });
        }
      },
    },
    number: {
      min: t('validated.min.numeric'),
      positive: ({ path }) => {
        return t('validated.positive', { name: t(`label.${path}`) });
      },
      integer: ({ path }) => {
        return t('validated.integer', { name: t(`label.${path}`) });
      },
    },
    string: {
      email: t('validated.email'),
      max: ({ path, max }) => {
        return t('validated.max.string', {
          name: t(`label.${path}`),
          max: max,
        });
      },
      min: ({ path, min }) => {
        return t('validated.min.string', {
          name: t(`label.${path}`),
          min: min,
        });
      },
    },
    date: {
      min: ({ path, min }) => {
        return t('validated.min_date', { name: t(`label.${path}`) });
      },
    },
  });
}

i18n.use(initReactI18next).init(
  {
    defaultNS: 'en',
    fallbackLng: 'en',
    debug: false,
    lng: 'ko',
    resources,
  },
  buildYupLocale,
);

const I18n = i18n;
const t: TFunction = (...args: Parameters<TFunction>) => I18n.t(...args);

export { I18n, t };
