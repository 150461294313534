import React from 'react';
import { Card, Table as AntTable } from 'antd'
import { useTranslation } from 'react-i18next';

import useTable from 'src/hook/useTable';
import column from './column';
import { useAppDispatch } from 'src/store'

type Props = {
  loading: boolean;
  entities: Response.UserEntity[];
};
const Table: React.FC<Props> = ({ entities, loading }) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { pagination, rowSelection, Selected, rowSelected, onChange } =
    useTable<Response.UserEntity>();

  return (
    <Card
      title={
        <span>
          <Selected>
            {t('rows_selected', {
              count: rowSelected.length,
            })}
          </Selected>
        </span>
      }
    >
      <div>
        <AntTable
          rowKey="id"
          loading={loading}
          columns={column(t, dispatch)}
          dataSource={entities || []}
          pagination={pagination}
          rowSelection={rowSelection}
          onChange={onChange}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </Card>
  );
};

export default Table;
