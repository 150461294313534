import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import EarnContainer from 'src/containers/Earn';
import Search from 'src/containers/Earn/Search';
import { fetchResource } from 'src/useCase/earn';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    dispatch(fetchResource(query));
  }, [query]);

  return (
    <ContentWrapper title={'Earn Management'}>
      <Search/>
      <EarnContainer />
    </ContentWrapper>
  );
};

export const PATH = '/earns' as const;
export default Page;
