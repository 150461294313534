import React from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  TeamOutlined,
  ProfileOutlined,
  TrophyOutlined,
  ClockCircleFilled,
  SettingFilled,
  ShopFilled,
  QqCircleFilled
} from '@ant-design/icons';

export const items: ItemType[] = [
  {
    label: <Link to="/">Dashboard</Link>,
    key: '/',
    icon: <HomeOutlined />,
  },
  {
    label: <Link to="/users">User Management</Link>,
    key: '/users',
    icon: <TeamOutlined />,
  },
  {
    label: <Link to="/projects">Project Management</Link>,
    key: '/projects',
    icon: <ProfileOutlined />,
  },
  {
    label: <Link to="/events">Event Management</Link>,
    key: '/events',
    icon: <ProfileOutlined />,
  },
  {
    label: <Link to="/challenges">Challenge Management</Link>,
    key: '/challenges',
    icon:<TrophyOutlined />,
  },
  {
    label: <Link to="/pairs">Pair Management</Link>,
    key: '/pairs',
    icon:<QqCircleFilled />,
  },
  {
    label: <Link to="/mints">Mint Transaction</Link>,
    key: '/mints',
    icon:<ClockCircleFilled />,
  },
  {
    label: <Link to="/swaps">Swap Transaction</Link>,
    key: '/swaps',
    icon:<ClockCircleFilled />,
  },
  {
    label: <Link to="/burns">Burn Transaction</Link>,
    key: '/burns',
    icon:<ClockCircleFilled />,
  },
  {
    label: <Link to="/onchain_logs">OnChain Logs Transaction</Link>,
    key: '/onchain_logs',
    icon:<ClockCircleFilled />,
  },
  {
    label: <Link to="/blacklists">Blacklist Setting</Link>,
    key: '/blacklists',
    icon:<SettingFilled />,
  },
  {
    label: <Link to="/earns">Earn Management</Link>,
    key: '/earns',
    icon:<ShopFilled />,
  },
];
