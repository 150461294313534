const column = (t, dispatch) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
    render: (_, row) => {
      return (
        <div><div>{row?.event?.name || '-'}</div></div>
      )
    }
  },
  {
    title: 'Challenge',
    dataIndex: 'Challenge',
    key: 'Challenge',
    render: (_, row) => {
      return (
        <div><div>{row?.challenge?.name || '-'}</div></div>
      )
    }
  },
  {
    title: 'User Address',
    dataIndex: 'userAddress',
    key: 'userAddress',
    render: (_, row) => {
      return (
        <div><div>{row?.user?.address || '-'}</div></div>
      )
    }
  },
  {
    title: 'Point',
    dataIndex: 'Point',
    key: 'Point',
    render: (_, row) => {
      return (
        <div><div>{Number(row?.point).toLocaleString() || '-'}</div></div>
      )
    }
  },
];

export default column;
