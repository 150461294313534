import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import userAction from 'src/store/domain/user/list';
import appAction from 'src/store/ui/app';
import userRequest from 'src/api/requests/user';

interface FetchResource extends SearchParams.Users {}

const getParams = (args: any) => {
  const {
    take,
    page,
    keyword,
  } = args;

  const params: any = {
    take,
    page,
    keyword,
  };

  return params;
};

export type CreatePartnerParams = {
    "userId": string,
    "partnerLevel": string,
    "referralCode": string,
    "commission": number,
}
export const fetchResource = createAsyncThunk<void, FetchResource>(
  'get_users',
  async (args, { dispatch }) => {
    try {
      dispatch(userAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
      const res = await userRequest.getList(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(userAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(userAction.updateLoading(false));
    } catch (err) {
      dispatch(userAction.updateLoading(false));
    }
  },
);
