import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import BlacklistContainer from 'src/containers/Blacklist';
import { fetchResource } from 'src/useCase/blacklist';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';
import Search from "../../containers/Blacklist/Search";

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    dispatch(fetchResource(query));
  }, [dispatch, query]);

  return (
    <ContentWrapper title={'Blacklist Management'}>
      <Search/>
      <BlacklistContainer />
    </ContentWrapper>
  );
};

export const PATH = '/blacklists' as const;
export default Page;
