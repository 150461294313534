import React, {useState} from 'react';
import {
  Col,
  Form,
  FormItemProps,
  Row,
  Typography,
  AutoComplete,
  AutoCompleteProps,
} from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';
import { Info } from 'src/components/icon';
import projectRequest from "../../api/requests/projects";

type Props<T> = {
  autoCompleteProps?: AutoCompleteProps;
  controller: UseControllerProps<T>;
  errorCol?: 12 | 24;
  hasErrorTick?: boolean;
} & Omit<FormItemProps, 'children'>;

function AutoSuggestField<T extends Record<string, any>>(props: Props<T>) {
  const {
    autoCompleteProps,
    controller,
    errorCol = 24,
    label,
    hasErrorTick,
    ...rest
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;
  const [projectList, setProjectList] = useState<Array<any>>([]);
  const [projects, setProjects] = useState<Array<any>>([]);
  const [inputValue, setInputValue] = useState<string>('');


  const handleSearch = async (value: string) => {
    setInputValue(value);
    const param = {
      keyword: value
    }
    try {
      const res = await projectRequest.getList(param);
      const dataProjects = res?.data?.data;
      console.log('dataProjects' ,dataProjects)
      setProjects(dataProjects);
    } catch (error) {
      console.error('Error searching projects:', error);
    }
    const filteredProjects = projects.filter((project: any) =>
        project.name.toLowerCase().includes(value.toLowerCase())
    );
    setProjectList(filteredProjects);
  };

  const onSelect = (value: string, option: any) => {
    field.onChange(option.key);
      setInputValue(option.value);
  };

  return (
      <Form.Item
          colon={false}
          label={label}
          labelAlign="left"
          labelCol={label ? { span: 24 } : undefined}
          wrapperCol={label ? { span: 24 } : undefined}
          validateStatus={error ? 'error' : ''}
          {...rest}
      >
        <Row gutter={4}>
          <Col span={errorCol}>
            <Row gutter={8}>
              <Col span={24}>
                <AutoComplete
                    {...autoCompleteProps}
                    {...field}
                    onSearch={handleSearch}
                    onSelect={onSelect}
                    value={inputValue}
                    style={{ width: '100%' }}
                >
                  {projectList.map((project: any) => (
                      <AutoComplete.Option key = {project.id} value={project.name}>
                        {project.name}
                      </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Col>
            </Row>
          </Col>
          <Col span={errorCol}>
            {error && (
                <Typography.Text
                    className="ant-form-item-explain ant-form-item-explain-error"
                    type="danger"
                    ellipsis={{ tooltip: true }}
                    style={{ lineHeight: errorCol === 24 ? '16px' : '42px' }}
                >
                  {hasErrorTick && <Info />}
                  {error.message}
                </Typography.Text>
            )}
          </Col>
        </Row>
      </Form.Item>
  );
}

export default AutoSuggestField;

