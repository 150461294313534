import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import OnchainLogsContainer from 'src/containers/OnchainLogs';
import Search from 'src/containers/OnchainLogs/Search';
import { fetchOnchainLogsResource } from 'src/useCase/transaction';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    dispatch(fetchOnchainLogsResource(query));
  }, [query]);

  return (
    <ContentWrapper title={'OnChain Logs Management'}>
      <Search/>
      <OnchainLogsContainer />
    </ContentWrapper>
  );
};

export const PATH = '/onchain_logs' as const;
export default Page;
