import React from 'react';

import { IRouter } from 'src/interfaces/route';
import { PATH } from 'src/pages/project';
import { PATH as CREATE_PATH } from 'src/pages/project/create';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';

const DashboardRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/project')),
        title: 'Project Management',
      },
      {
        path: CREATE_PATH,
        element: loadable(() => import('src/pages/project/create')),
        title: 'Project Create',
      },
    ],
  },
];

export default DashboardRoute;
