import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux'
import { useAppSelector } from 'src/store/index'

type UserSearchOptions = {
  key: string
  value: string
  referralCode: string
  label: string
}

type State = {
  loading: boolean;
  entities: Response.UserEntity[];
  options: UserSearchOptions[];
};

const initialState: State = {
  loading: false,
  entities: [],
  options: [],
};

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'partner_list',
  initialState,
  reducers: {
    updateEntities: (state, action: PayloadAction<Response.UserEntity[]>) => {
      state.entities = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateOptions: (state, action: PayloadAction<UserSearchOptions[]>) => {
      state.options = action.payload;
    }
  },
});

export { reducer };
export const useGetOptions = () => {
  return useAppSelector((state: any) => state.domain.user.partner.options)
}
export default actions;
