import React, {useEffect, useState} from 'react';
import {Modal, message, Form, Button, Input, Select} from 'antd';
import { updateProject} from "../../../useCase/projects";
import {useAppDispatch} from "../../../store";
import projectRequest from "../../../api/requests/projects";
import {Option} from "antd/lib/mentions";

type Props = {
    isEditModalVisible: boolean;
    setIsEditModalVisible: (v: boolean) => void;
    itemToEdit: string | null;
};

const UpdateProjectForm: React.FC<Props> = ({ isEditModalVisible, setIsEditModalVisible, itemToEdit }) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [projectData, setProjectData] = useState<any>({});

    useEffect(() => {
        const fetchProjectDetails = async () => {
            if (itemToEdit) {
                try {
                    const res = await projectRequest.detailProject(itemToEdit);
                    setProjectData(res.data);
                    form.setFieldsValue(res.data.data);
                } catch (err) {
                    message.error('Failed to fetch project details');
                }
            }
        };

        fetchProjectDetails();
    }, [form, itemToEdit]);

    const handleCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleEditProject = async () => {
        try {
            const formData = form.getFieldsValue();
            await dispatch(updateProject({ id: itemToEdit, data: formData }));
            message.success('Project updated successfully');
            setIsEditModalVisible(false);
        } catch (error) {
            message.error('Failed to update project');
        }
    };

    return (
        <Modal
            title="Edit Project"
            visible={isEditModalVisible}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={() => setIsEditModalVisible(false)}>
                    Cancel
                </Button>,
                <Button key="edit" type="primary" onClick={handleEditProject}>
                    Edit
                </Button>,
            ]}
        >
            <Form
                form={form}
                initialValues={projectData.data}
                onFinish={handleEditProject}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 20 }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Token Symbol"
                    name="tokenSymbol"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Total Supply"
                    name="totalSupply"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Token Status"
                    name="tokenStatus"
                    initialValue={projectData?.data?.tokenStatus}
                >
                    <Select>
                        <Option value="Disable">Disable</Option>
                        <Option value="Active">Enable</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Reward Percentage"
                    name="rewardPercentage"
                    initialValue={projectData?.data?.tokenStatus}
                >
                    <Input />

                </Form.Item>

                <Form.Item
                    label="Total Participants"
                    name="totalParticipants"
                    initialValue={projectData?.data?.tokenStatus}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Time Remaining"
                    name="timeRemaining"
                    initialValue={projectData?.data?.tokenStatus}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateProjectForm;

