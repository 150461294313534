import React from 'react';
import { Modal, message} from 'antd';
import {deleteProject} from "../../../useCase/projects";
import {useAppDispatch} from "../../../store";
import {useNavigate} from "react-router-dom";


type Props = {
    isConfirmDeleteShow: boolean;
    setIsConfirmDeleteShow: (v: boolean) => void;
    itemToDelete: string | null;

};

const ConfirmDeleteForm: React.FC<Props> = ({ isConfirmDeleteShow, setIsConfirmDeleteShow,itemToDelete }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleConfirmDelete = () => {
        if (itemToDelete) {
            dispatch(deleteProject(itemToDelete));
            message.success('Delete project successfully');
            navigate('/projects');
            setIsConfirmDeleteShow(false);

        }
    };

    return (
        <Modal
            title="Are you sure you want to delete this item?"
            visible={isConfirmDeleteShow}
            onOk={() => handleConfirmDelete()}
            onCancel={() => setIsConfirmDeleteShow(false)}
            okText="Yes"
            cancelText="No"
        ></Modal>
    );
};

export default ConfirmDeleteForm;

