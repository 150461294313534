import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import UserContainer from 'src/containers/User';
// import Search from 'src/containers/User/Search';
import { fetchResource } from 'src/useCase/user';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    dispatch(fetchResource(query));
  }, [query]);

  return (
    <ContentWrapper title={'User Management'}>
      {/*<Search/>*/}
      <UserContainer />
    </ContentWrapper>
  );
};

export const PATH = '/users' as const;
export default Page;
