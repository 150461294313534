import HTTPRequest from '../index';

const apiPath = '/admin/earns';

class Earn extends HTTPRequest {
  getList(params: any) {
    return this.get(apiPath, { params });
  }
}

export default new Earn();
