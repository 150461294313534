
const column = (t, dispatch) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'ID',
  },

  {
    title: 'Block Number',
    dataIndex: 'blockNumber',
    key: 'blockNumber',
  },
  {
    title: 'Block Hash',
    dataIndex: 'blockHash',
    key: 'blockHash',
  },
  {
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
  },
  {
    title: 'To',
    dataIndex: 'to',
    key: 'to',
  },
  {
    title: 'Input',
    dataIndex: 'input',
    key: 'input',
  },
  {
    title: 'Function Name',
    dataIndex: 'functionName',
    key: 'functionName',
  },
  {
    title: 'Method Id',
    dataIndex: 'methodId',
    key: 'methodId',
  },
  {
    title: 'Contract Address',
    dataIndex: 'contractAddress',
    key: 'contractAddress',
  },
];

export default column;
