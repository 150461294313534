import React, { useEffect, useState } from 'react'

import { ContentWrapper } from 'src/components/common';
import DashboardContainer from 'src/containers/Dashboard';
import { useAppDispatch } from 'src/store'
import useQueryUrl from 'src/hook/useQueryUrl'
import { Calendar } from 'antd';
import { fetchResource } from 'src/useCase/dashboard'

const Page: App.FC = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<SearchParams.Users>();

  // useEffect(() => {
  //   dispatch(fetchResource(query));
  // });
  return (
    <ContentWrapper title="Dashboard">
      {/*<DashboardContainer />*/}
      <Calendar/>
    </ContentWrapper>
  );
};

export const PATH = '/' as const;
export default Page;
