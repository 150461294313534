import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import onchainLogsAction from 'src/store/domain/transaction/onchain_logs';
import mintAction from 'src/store/domain/transaction/mint';
import swapAction from 'src/store/domain/transaction/swap';
import burnAction from 'src/store/domain/transaction/burn';
import appAction from 'src/store/ui/app';
import transactionRequest from 'src/api/requests/transaction';

interface FetchResource extends SearchParams.Users {}

const getParams = (args: any) => {
  const {
    take,
    page,
    hash,
    userAddress,
  } = args;

  const params: any = {
    take,
    page,
    hash,
    userAddress
  };

  return params;
};

export const fetchOnchainLogsResource = createAsyncThunk<void, FetchResource>(
  'get_onchain_logs',
  async (args, { dispatch }) => {
    try {
      dispatch(onchainLogsAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
      const res = await transactionRequest.listChainLogs(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(onchainLogsAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(onchainLogsAction.updateLoading(false));
    } catch (err) {
      dispatch(onchainLogsAction.updateLoading(false));
    }
  },
);

export const fetchSwapsResource = createAsyncThunk<void, FetchResource>(
  'get_swaps',
  async (args, { dispatch }) => {
    try {
      dispatch(swapAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
      const res = await transactionRequest.listSwaps(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(swapAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(swapAction.updateLoading(false));
    } catch (err) {
      dispatch(swapAction.updateLoading(false));
    }
  },
);

export const fetchMintsResource = createAsyncThunk<void, FetchResource>(
  'get_mints',
  async (args, { dispatch }) => {
    try {
      dispatch(mintAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
      const res = await transactionRequest.listMints(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(mintAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(mintAction.updateLoading(false));
    } catch (err) {
      dispatch(mintAction.updateLoading(false));
    }
  },
);

export const fetchBurnsResource = createAsyncThunk<void, FetchResource>(
  'get_burns',
  async (args, { dispatch }) => {
    try {
      dispatch(burnAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
      const res = await transactionRequest.listBurns(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(burnAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(burnAction.updateLoading(false));
    } catch (err) {
      dispatch(burnAction.updateLoading(false));
    }
  },
);
