import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import {createProject, createProjectSocial} from "../../../useCase/projects";

export type FormValues = {
  name: string,
  tokenSymbol: string,
  totalSupply: string,
  tokenStatus: string,
  rewardPercentage: number,
  totalParticipants: number,
  timeRemaining: number,
  url: string,
  telegramName: string,
  telegramId: string,
  telegramUrl: string,
  twitterName: string,
  twitterId: string,
  twitterUrl: string,
  projectId: string,
  type: string,
};

const useCreateProjectForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const defaultValues: FormValues = {
    name: '',
    tokenSymbol: '',
    totalSupply: '',
    tokenStatus: '',
    rewardPercentage: 0,
    totalParticipants: 0,
    timeRemaining: 0,
    url: '',
    telegramName: '',
    twitterName: '',
    telegramId: '',
    twitterId: '',
    projectId: '',
    telegramUrl: '',
    twitterUrl: '',
    type: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    tokenSymbol: Yup.string().required(),
    totalSupply: Yup.string().required(),
    tokenStatus: Yup.string().required(),
    rewardPercentage: Yup.string().required(),
    totalParticipants: Yup.string().required(),
    timeRemaining: Yup.string().required(),
  });

  const handleSubmit = (callback:any) => {
    form.handleSubmit(async (data) => {
      const projectId = await dispatch(createProject(data));
      const createSocial = async (socialData:any, type:string) => {
        if (socialData.url && socialData.socialId && socialData.name) {
          const social = {
            url: socialData.url,
            socialId: socialData.socialId,
            name: socialData.name,
            projectId: projectId.payload,
            type: type,
          };

          await dispatch(createProjectSocial(social));
        }
      };
      await Promise.all([
        createSocial({
          url: data.telegramUrl,
          socialId: data.telegramId,
          name: data.telegramName,
        }, 'Telegram'),
        createSocial({
          url: data.twitterUrl,
          socialId: data.twitterId,
          name: data.twitterName,
        }, 'Twitter'),
      ]);
      message.success('Create project successfully');
      navigate('/projects');
      await form.reset({ ...defaultValues });
      callback(false);
    })();
  };

  const resetForm = async () => {
    await form.reset({ ...defaultValues });
  };

  const form = useForm<FormValues>({
    defaultValues: defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  return {
    form,
    resetForm,
    handleSubmit,
  };
};

export default useCreateProjectForm;
