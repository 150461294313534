import React from 'react';

import { IRouter } from 'src/interfaces/route';
import { PATH as BLACKLIST_LOGS_PATH } from 'src/pages/blacklist';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';

const DashboardRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: BLACKLIST_LOGS_PATH,
        element: loadable(() => import('src/pages/blacklist')),
        title: 'Blacklist Management',
      },
    ],
  },
];

export default DashboardRoute;
