import React from 'react';
import dayjs from 'dayjs'

const column = (t, dispatch) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (_, row) => {
      return (
        <div>
          <span>{row?.id || '-'}</span>
        </div>
      );
    }
  },

  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, row) => {
      return (
        <div>
          <span>{row?.name || '-'}</span>
        </div>
      );
    }
  },
  {
    title: 'Description',
    key: 'description',

    render: (_, row) => {
      return <span>{row.description || '-'}</span>;
    },
  },
  {
    title: 'Start Time',
    key: 'startTime',

    render: (_, row) => {
      return <span>{row?.startTime ?  dayjs.unix(parseInt(row?.startTime)).format('YYYY.MM.DD, HH:mm:ss') : '-'}</span>;
    },
  },
  {
    title: 'End Time',
    key: 'endTime',

    render: (_, row) => {
      return <span>{row?.startTime ?  dayjs.unix(parseInt(row?.endTime)).format('YYYY.MM.DD, HH:mm:ss') : '-'}</span>;
    },
  },
];

export const expandableColumn = (t, record) => [
  {
    title: "ID",
    key: 'id',
    render: (_, row) => {

      return <div>{row?.id || '-'}</div>
    },
  },
  {
    title: "Type",
    key: 'type',
    render: (_, row) => {

      return <div>{row?.type || '-'}</div>
    },
  },
  {
    title: "Name",
    key: 'name',
    render: (_, row) => {

      return <div>{row?.name || '-'}</div>
    },
  },
  {
    title: "Description",
    key: 'description',
    render: (_, row) => {

      return <div>{row?.description || '-'}</div>
    },
  },
  {
    title: "Key",
    key: 'key',
    render: (_, row) => {

      return <div>{row?.key || '-'}</div>
    },
  },
  {
    title: "Level",
    key: 'level',
    render: (_, row) => {

      return <div>{row?.level || '-'}</div>
    },
  },
  {
    title: "Goal",
    key: 'goal',
    render: (_, row) => {

      return <div>${Number(row?.goal).toLocaleString() || '-'}</div>
    },
  },
  {
    title: "Earn Value",
    key: 'earnValue',
    render: (_, row) => {

      return <div>{Number(row?.earnValue).toLocaleString() || '-'}</div>
    },
  },
];

export default column;

