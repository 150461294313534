import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import EventContainer from 'src/containers/Event';
import { fetchResource } from 'src/useCase/events';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchResource(query));
  }, [query]);

  return (
    <ContentWrapper title={'Event Management'} extra={
      <React.Fragment>
        <Button type="primary" onClick={() => navigate('/events/create')}>New Event</Button>
      </React.Fragment>
    }>
      <EventContainer />
    </ContentWrapper>
  );
};

export const PATH = '/events' as const;
export default Page;
