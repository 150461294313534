import React from 'react';
import styled from 'styled-components';
import { Alert, Button, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import sharkswap_logo from 'src/assets/sharkswap_logo.png';
import { InputField } from 'src/components/form';
import { FormValues, useLogin } from './useLogin';

const Container: React.FC = () => {
  const { t } = useTranslation();
  const { form, onSubmit } = useLogin();
  const { control, handleSubmit, formState } = form;

  return (
    <Wrapper>
      <WrapperDetail justify="center" align="middle">
        <Col xl={6}>
          <TitleImg>
            <img src={sharkswap_logo} alt="" className="img2" width={300} />
          </TitleImg>
          <StyleForm onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={8}>
                <Label>Email</Label>
              </Col>
              <Col xs={24}>
                <InputField<FormValues>
                  controller={{
                    control,
                    name: 'email',
                  }}
                  inputProps={{
                    placeholder: 'User name',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <Label>Password</Label>
              </Col>
              <Col xs={24}>
                <InputField<FormValues>
                  controller={{
                    control,
                    name: 'password',
                  }}
                  inputProps={{
                    placeholder: 'Password',
                    type: 'password',
                  }}
                />
              </Col>
            </Row>
            <StyleButton
              type="primary"
              htmlType="submit"
              block
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('login')}
            </StyleButton>
          </StyleForm>
          {formState.errors.email?.type === 'validate' &&
            formState.errors.password?.type === 'validate' && (
              <Alert
                showIcon
                type="error"
                message={t('message.incorrect_email_or_password')}
                style={{ marginBottom: 20 }}
              />
            )}
        </Col>
      </WrapperDetail>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #041524;
`;

const WrapperDetail = styled(Row)`
  height: 100vh;
`;

const TitleImg = styled.div`
  text-align: center;
  h1 {
    text-transform: uppercase;
  }
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 550;
  display: block;
  margin-bottom: 10px;
  color: white;
`;

const StyleButton = styled(Button)`
  border-radius: 5px;
`;

const StyleForm = styled(Form)`
  margin: 20px 0;
`;

export default Container;
