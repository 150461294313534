import { useForm } from 'react-hook-form';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import useQueryUrl from 'src/hook/useQueryUrl';
import history from 'src/libs/history';

export type FormValues = {
  hash?: string;
};

const useSearch = () => {
  const { query, setSearchQuery } = useQueryUrl<FormValues>();
  const location = useLocation();

  const defaultValues: FormValues = {
    hash: '',
  };

  const handleSearch = () => {
    form.handleSubmit((data) => {
      history.push({
        search: qs.stringify({
          ...data,
          page: 1,
        }),
      });
    })();
  };

  const resetForm = async () => {
    await form.reset({ ...defaultValues });
    setSearchQuery('');
  };

  const form = useForm<FormValues>({
    defaultValues: location.search === '' ? defaultValues : query,
    shouldFocusError: true,
    mode: 'all',
  });

  return {
    form,
    resetForm,
    handleSearch,
  };
};

export default useSearch;
