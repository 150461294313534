import HTTPRequest from '../index';

const apiPath = '/admin/pairs';

class Pair extends HTTPRequest {
  getList(params: any) {
    return this.get(apiPath, { params });
  }
}

export default new Pair();
