import React from 'react';

export const column = (t, record) => [
  {
    title: "ID",
    key: 'id',
    render: (_, row) => {

      return <div>{row?.id || '-'}</div>
    },
  },
  {
    title: "Type",
    key: 'type',
    render: (_, row) => {

      return <div>{row?.type || '-'}</div>
    },
  },
  {
    title: "Name",
    key: 'name',
    render: (_, row) => {

      return <div>{row?.name || '-'}</div>
    },
  },
  {
    title: "Description",
    key: 'description',
    render: (_, row) => {

      return <div>{row?.description || '-'}</div>
    },
  },
  {
    title: "Key",
    key: 'key',
    render: (_, row) => {

      return <div>{row?.key || '-'}</div>
    },
  },
  {
    title: "Level",
    key: 'level',
    render: (_, row) => {

      return <div>{row?.level || '-'}</div>
    },
  },
  {
    title: "Goal",
    key: 'goal',
    render: (_, row) => {

      return <div>${Number(row?.goal).toLocaleString() || '-'}</div>
    },
  },
  {
    title: "Earn Value",
    key: 'earnValue',
    render: (_, row) => {

      return <div>{Number(row?.earnValue).toLocaleString() || '-'}</div>
    },
  },
];

export default column;

