import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import ChallengeContainer from 'src/containers/Challenge';
import { fetchResource } from 'src/useCase/challenge';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    const params = {
      ...query,
      take: query.take || '100'
    }
    dispatch(fetchResource(params));
  }, [query]);

  return (
    <ContentWrapper title={'Challenge Management'}>
      <ChallengeContainer />
    </ContentWrapper>
  );
};

export const PATH = '/challenges' as const;
export default Page;
