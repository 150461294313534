import HTTPRequest from '../index';

const apiPath = '/admin/blacklist';


class Blacklist extends HTTPRequest {
  getList(params: Payload.Users) {
    return this.get(apiPath, { params });
  }

  create(data){
    return this.post(apiPath, data)
  }

  detail(id){
    return this.get(`${apiPath}/${id}`)
  }

  update(id, data){
    return this.put(`${apiPath}/${id}`, data)
  }

  deleteBlacklist(id){
    return this.delete(`${apiPath}/${id}`)
  }
}

export default new Blacklist();
