
const column = (t, dispatch) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'ID',
  },
  {
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
  },
  {
    title: 'Pair ID',
    dataIndex: 'pairId',
    key: 'pairId',
  },
  {
    title: 'Sender',
    dataIndex: 'sender',
    key: 'sender',
  },
  {
    title: 'To',
    dataIndex: 'to',
    key: 'to',
  },
  {
    title: 'Amount0',
    dataIndex: 'amount0',
    key: 'amount0',
  },
  {
    title: 'Amount1',
    dataIndex: 'amount1',
    key: 'amount1',
  },
  {
    title: 'Amount USD',
    dataIndex: 'amountUSD',
    key: 'amountUSD',
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
];

export default column;
