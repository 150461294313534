import React, { useState } from 'react';
import { Card, Table as AntTable, Button } from 'antd';

import { useTranslation } from 'react-i18next';

import useTable from 'src/hook/useTable';
import column from './column';
import { useAppDispatch } from 'src/store';
import CreateModal from './CreateModal/index';
import ConfirmDeleteForm from '../Delete/index';

type Props = {
    loading: boolean;
    entities: Response.UserEntity[];
};

const Table: React.FC<Props> = ({ entities, loading }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { pagination, rowSelection, Selected, rowSelected, onChange } = useTable<Response.UserEntity>();
    const [isShow, setIsShow] = useState(false);
    const [isConfirmDeleteShow, setIsConfirmDeleteShow] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<string | null>(null);

    const handleDelete = (id) => {
        setItemToDelete(id);
        setIsConfirmDeleteShow(true);
    };

    return (
        <div>
            <Card
                title={
                    <span>
            <Selected>
              {t('rows_selected', {
                  count: rowSelected.length,
              })}
            </Selected>
          </span>
                }
                extra={
                    <Button type="primary" onClick={() => setIsShow(true)}>
                        Create
                    </Button>
                }
            >
                <div>
                    <AntTable
                        rowKey="id"
                        loading={loading}
                        columns={column(t, dispatch,handleDelete)}
                        dataSource={entities || []}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        onChange={onChange}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Card>
            <CreateModal isShow={isShow} setIsShow={setIsShow} />
            <ConfirmDeleteForm isConfirmDeleteShow={isConfirmDeleteShow} setIsConfirmDeleteShow={setIsConfirmDeleteShow} itemToDelete={itemToDelete} />

        </div>
    );
};

export default Table;
