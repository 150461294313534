import React  from 'react';
import styled from 'styled-components';
import {Col, Row, Button, Typography} from 'antd';
import {InputField} from 'src/components/form'
import useForm, { FormValues } from './useForm';
import { useNavigate } from 'react-router-dom';

const NewProjectForm = () => {
  const navigate = useNavigate();
    const { Title } = Typography;

    const { form, handleSubmit } = useForm();
  const { control } = form;
  return (
    <Wrapper>
      <Row gutter={24} justify='space-between' className="submit-btn">
        <Button onClick={() => navigate('/projects')}>Back To List</Button>
        <Button type='primary' onClick={handleSubmit}>Submit Project</Button>
      </Row>
      <Row gutter={24}>
        <Col md={24}>
          <InputField<FormValues>
            controller={{
              control,
              name: 'name',
            }}
            inputProps={{
              placeholder: 'Enter the project name.',
            }}
            label={'Project Name'}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          />
        </Col>
        <Col md={24}>
          <InputField<FormValues>
            controller={{
              control,
              name: 'tokenSymbol',
            }}
            label={'Event Token Symbol'}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          />
        </Col>
          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'totalSupply',
                  }}
                  label={'Project Total Supply'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>
          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'tokenStatus',
                  }}
                  label={'Project Token Status'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>
          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'rewardPercentage',
                  }}
                  label={'Project Reward Percentage '}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'totalParticipants',
                  }}
                  label={'Project Total Participants'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'timeRemaining',
                  }}
                  label={'Project Time Remaining'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>
          <Col md={24}>
              <Title level={5} style={{ fontWeight: 'bold' }}>TELEGRAM</Title>
          </Col>
          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'telegramId',
                  }}
                  label={'Telegram Id'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'telegramUrl',
                  }}
                  label={'Telegram Url'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'telegramName',
                  }}
                  label={'Telegram Name'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

          <Col md={24}>
              <Title level={5} style={{ fontWeight: 'bold' }}>TWITTER</Title>
          </Col>
          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'twitterId',
                  }}
                  label={'Twitter Id'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'twitterUrl',
                  }}
                  label={'Twitter Url'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

          <Col md={24}>
              <InputField<FormValues>
                  controller={{
                      control,
                      name: 'twitterName',
                  }}
                  label={'Twitter Name'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
              />
          </Col>

      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-radio-button-wrapper {
    margin-right: 6px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .submit-btn {
    margin-bottom: 30px;
    padding: 10px;
  }
`;

export default NewProjectForm;
