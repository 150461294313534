import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import BurnContainer from 'src/containers/Burn';
import Search from 'src/containers/Burn/Search';
import { fetchBurnsResource } from 'src/useCase/transaction';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    dispatch(fetchBurnsResource(query));
  }, [query]);

  return (
    <ContentWrapper title={'Burn Logs Management'}>
      <Search/>
      <BurnContainer />
    </ContentWrapper>
  );
};

export const PATH = '/burns' as const;
export default Page;
