import React, { useState } from 'react';
import { Menu, Layout, Row, MenuProps } from 'antd';
import styled from 'styled-components';

import { APP_HEADER_HEIGHT } from 'src/constants/app';
import logo from 'src/assets/sharkswap_logo.svg';
import history from 'src/libs/history';
import { items } from './menuItems';

const { Sider } = Layout;

type Props = {
  collapsed: boolean;
};

const AppSider: React.FC<Props> = ({ collapsed }) => {
  const [openKeys, setOpenKeys] = useState<React.Key[]>([]);

  const getOpenKey = () => {
    const [, paths] = history.location.pathname.split('/');
    return paths || '';
  };

  const getSelectedKey = () => {
    const path = history.location.pathname;
    const REGEXNumber = /\d/;

    if (REGEXNumber.test(path)) {
      const newPath = history.location.pathname.replace(/[0-9]/g, '');
      return [newPath.substring(0, newPath.length - 1)];
    }

    return [history.location.pathname.split('/').slice(0, 3).join('/')];
  };

  const defaultOpenKeys = [getOpenKey(), ...(openKeys as string[])];

  const onClick: MenuProps['onClick'] = (e) => {
    setOpenKeys(e.keyPath);
  };

  return (
    <Sider width={250} collapsed={collapsed} theme="dark">
      <StyleLogo>
        <img src={logo} alt="logo"/>
      </StyleLogo>
      <Menu
        mode="inline"
        theme="dark"
        selectedKeys={getSelectedKey()}
        openKeys={collapsed ? undefined : defaultOpenKeys}
        defaultOpenKeys={collapsed ? defaultOpenKeys : undefined}
        style={{
          // maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
          borderRight: 0,
        }}
        onOpenChange={setOpenKeys}
        items={items}
        onClick={onClick}
      />
    </Sider>
  );
};

const StyleLogo = styled(Row)`
  height: ${APP_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
  }
`;

export default AppSider;
