import { combineReducers } from '@reduxjs/toolkit';

import user from './user';
import dashboard from './dashboard';
import event from './event';
import challenge from './challenge';
import transaction from './transaction';
import blacklist from './blacklist';
import earn from './earn';
import pair from './pair';
import project from "./project";

export default combineReducers({
  user,
  dashboard,
  event,
  challenge,
  transaction,
  blacklist,
  earn,
  pair,
  project,
});
