import HTTPRequest from '../index';

const apiPath = '/admin/events';


class Events extends HTTPRequest {
  getList(params: Payload.Users) {
    return this.get(apiPath, { params });
  }

  create(data){
    return this.post(apiPath, data)
  }

  detail(id){
    return this.get(`${apiPath}/${id}`)
  }

  update(id, data){
    return this.put(`${apiPath}/${id}`, data)
  }

  deleteAnnouncement(id){
    return this.delete(`${apiPath}/${id}`)
  }
}

export default new Events();
