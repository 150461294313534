
const column = (t, dispatch) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'ID',
  },
  {
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
  },
  {
    title: 'Pair ID',
    dataIndex: 'pairId',
    key: 'pairId',
  },
  {
    title: 'Sender',
    dataIndex: 'sender',
    key: 'sender',
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
  },
  {
    title: 'To',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: 'Amount0In',
    dataIndex: 'amount0In',
    key: 'amount0In',
  },
  {
    title: 'Amount1In',
    dataIndex: 'amount1In',
    key: 'amount1In',
  },
  {
    title: 'Amount0Out',
    dataIndex: 'amount0Out',
    key: 'amount0Out',
  },
  {
    title: 'Amount1Out',
    dataIndex: 'amount1Out',
    key: 'amount1Out',
  },
  {
    title: 'Amount USD',
    dataIndex: 'amountUsd',
    key: 'amountUsd',
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
];

export default column;
