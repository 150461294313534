import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import projectAction from 'src/store/domain/project/list';
import appAction from 'src/store/ui/app';
import projectRequest from 'src/api/requests/projects';
interface FetchResource extends SearchParams.Users {}

const getParams = (args: any) => {
  const {
    take,
    page,
    keyword,
  } = args;

  const params: any = {
    take,
    page,
    keyword,
  };

  return params;
};

export const fetchResourceProject = createAsyncThunk<void, FetchResource>(
  'get_projects',
  async (args, { dispatch }) => {
    try {
      dispatch(projectAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
        console.log(params)
      const res = await projectRequest.getList(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(projectAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(projectAction.updateLoading(false));
    } catch (err) {
      dispatch(projectAction.updateLoading(false));
    }
  },
);

export const createProject = createAsyncThunk<any, any>(
  'project_create_new',
  async (data, { dispatch, rejectWithValue }) => {
    try {
     const {data:project}= await projectRequest.create(data);
      dispatch(fetchResourceProject({}));
      return project?.data?.id
    } catch (err: any) {
      rejectWithValue('Something went wrong!');
    }
  },
);

export const deleteProject = createAsyncThunk<any, string>(
    'project_delete',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await projectRequest.deleteProject(id);
            if (res){
                dispatch(fetchResourceProject({}));
            }
        } catch (err: any) {
            rejectWithValue('Something went wrong!');
        }
    }
);
export const updateProject = createAsyncThunk<any, { id: string | null, data: any }>(
    'project_update',
    async ({ id, data }, { dispatch, rejectWithValue }) => {
        try {
            const res = await projectRequest.updateProject(id, data);
            if (res) {
                dispatch(fetchResourceProject({}));
            }
        } catch (err: any) {
            rejectWithValue('Something went wrong!');
        }
    }
);

export const createProjectSocial = createAsyncThunk<any, any>(
    'project_social_create_new',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            await projectRequest.createProjectSocials(data);

            dispatch(fetchResourceProject({}));
        } catch (err: any) {
            rejectWithValue('Something went wrong!');
        }
    },
);

