import HTTPRequest from '../index';

const apiPath = '/admin/users';

class User extends HTTPRequest {
  getList(params: any) {
    return this.get<Response.UserListResponse>(apiPath, { params });
  }
}

export default new User();
