import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import SwapContainer from 'src/containers/Swap';
import Search from 'src/containers/Swap/Search';
import { fetchSwapsResource } from 'src/useCase/transaction';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    dispatch(fetchSwapsResource(query));
  }, [query]);

  return (
    <ContentWrapper title={'Swap Logs Management'}>
      <Search/>
      <SwapContainer />
    </ContentWrapper>
  );
};

export const PATH = '/swaps' as const;
export default Page;
