import React from 'react';
import {Button, Space} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const column = (t, dispatch,handleDelete,handleEdit) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (_, row) => {
      return (
        <div>
          <span>{row?.id || '-'}</span>
        </div>
      );
    }
  },

  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, row) => {
      return (
        <div>
          <span>{row?.name || '-'}</span>
        </div>
      );
    }
  },
  {
    title: 'Token Symbol',
    key: 'tokenSymbol',

    render: (_, row) => {
      return <span>{row.tokenSymbol || '-'}</span>;
    },
  },
  {
    title: 'Total Supply',
    key: 'totalSupply',

    render: (_, row) => {
      return <span>{row.totalSupply || '-'}</span>;
    },
  },
  {
    title: 'Token Status',
    key: 'tokenStatus',

    render: (_, row) => {
      return <span>{row.tokenStatus || '-'}</span>;
    },
  },
  {
    title: 'Reward Percentage',
    key: 'rewardPercentage',

    render: (_, row) => {
      return <span>{row.rewardPercentage || '-'}</span>;
    },
  },
  {
    title: 'Total Participants',
    key: 'totalParticipants',

    render: (_, row) => {
      return <span>{row.totalParticipants || '-'}</span>;
    },
  },
  {
    title: 'Time Remaining',
    key: 'timeRemaining',

    render: (_, row) => {
      return <span>{row.timeRemaining || '-'}</span>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record: any) => (
        <Space>
          {/* Nút Delete */}
          <Button
              danger
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.id)}
          >
          </Button>

          <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record.id)}
          >
          </Button>
        </Space>
    ),
  },
];

export const expandableColumn = (t, record) => [
  {
    title: "ID",
    key: 'id',
    render: (_, row) => {

      return <div>{row?.id || '-'}</div>
    },
  },
  {
    title: "Name",
    key: 'name',
    render: (_, row) => {

      return <div>{row?.name || '-'}</div>
    },
  },
  {
    title: "Url",
    key: 'url',
    render: (_, row) => {

      return <div>{row?.url || '-'}</div>
    },
  },
  {
    title: "Type",
    key: 'type',
    render: (_, row) => {

      return <div>{row?.type || '-'}</div>
    },
  },
  {
    title: "Social Id",
    key: 'socialId',
    render: (_, row) => {

      return <div>{row?.socialId || '-'}</div>
    },
  },
  {
    title: "Project Id",
    key: 'projectId',
    render: (_, row) => {

      return <div>{row?.projectId || '-'}</div>
    },
  }
];

export default column;

