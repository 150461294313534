import React, { PropsWithChildren } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

import { APP_HEADER_HEIGHT } from 'src/constants/app';

const { Title } = Typography;

type Props = {
  title: string;
  extra?: React.ReactElement;
};

const ContentWrapper: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  extra,
}) => {
  return (
    <Wrapper>
      <Title
        level={4}
        style={{
          marginBottom: 24,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        {extra}
      </Title>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: ${`calc(100vh - ${APP_HEADER_HEIGHT}px)`};
  width: 100%;
  padding: 24px;
`;

export default ContentWrapper;
