import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { APP_HEADER_HEIGHT } from 'src/constants/app';

const ContentWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <React.Fragment>{children}</React.Fragment>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: ${`calc(100vh - ${APP_HEADER_HEIGHT}px)`};
  width: 100%;
  padding: 0 24px 24px;
`;

export default ContentWrapper;
