import HTTPRequest from '../index';

const apiPath = '/admin/projects';


class Projects extends HTTPRequest {
  getList(params: Payload.Users) {
    return this.get(apiPath, { params });
  }

  create(data){
    return this.post(apiPath, data)
  }

  createProjectSocials(data){
    return this.post(`${apiPath}/socials`, data)
  }

  detailProject(id){
    return this.get(`${apiPath}/${id}`)
  }

  updateProject(id, data){
    return this.put(`${apiPath}/${id}`, data)
  }

  deleteProject(id){
    return this.delete(`${apiPath}/${id}`)
  }
}

export default new Projects();
