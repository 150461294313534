import { AxiosRequestConfig } from 'axios';

export const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const DATE_TIME = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_HOUR = 'YYYY-MM-DD HH:mm:ss';
export const HOUR_MIN_KO = 'hhAmm시';
export const HOUR_MIN = 'HH:mm';
export const HOUR_MIN_SECOND = 'HH:mm:ss';
export const YEAR_MONTH_KO = 'YYYY년 MM월';
export const TIME_WITH_SECONDS = 'LTS';

export const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_BASE_API_URL}` || 'http://localhost:3000',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'lt_admin_access_token',
  REFRESH_TOKEN: 'lt_admin_refresh_token',
  AUTH_ME_INFO: 'lt_admin_me_info',
  LANG: 'lt_admin_lang',
  THEME: 'lt_admin_theme',
};

export const PER = {
  DEFAULT: 100,
  USER: 10,
};

export const fileTypes = ['image/png', 'image/jpeg'];
export const fileSize = 10; //Mb

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
  SEMI_DARK = 'semi_dark',
}

export enum LANG {
  KO = 'ko',
  EN = 'en',
}

export enum SORT_ORDER {
  DESC = 'desc',
  ASC = 'asc',
}

export const APP_HEADER_HEIGHT = 64;

export const DEFAULT_LIMIT = 50;

export const DEFAULT_PERCENT = 1;

export const REGEX = {
  REGEX_NUMBER: /(.*\d.*)/,
  REGEX_UPPER_CASE: /(.*[A-Z].*)/,
  REGEX_LOWER_CASE: /(.*[a-z].*)/,
  REGEX_SPECIAL_CHARACTER: /(.*\W.*)/,
  REGEX_PASSWORD_MATCHES: /^[a-zA-Z0-9!@#$%^&*()_=;'?{}~<>|[\]\\-`.+,/\\"]*$/,
  REGEX_EMAIL_MATCHES: /^[a-zA-Z0-9!@#$&()\\-`.+,/\\"]*$/,
  REGEX_PRODUCT_THICKNESS: /^[0-9x."]*$/,
  REGEX_ONLY_HALFWIDTH_CHARACTERS: /^[a-zA-Z0-9"]*$/,
  REGEX_DIMENSIONS: /^(\d+)x(\d+)x(\d+)$/,
  REGEX_ONLY_NUMER: /^-?\d*(\.\d*)?$/,
  REGEX_ONLY_INTEGER_NUMER: /^-?\d*?$/,
  REGEX_URL:
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
};

export const COLOR = {
  PRIMARY_COLOR: '#F2CA0D',
};
