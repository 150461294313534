import React from 'react';
import styled from 'styled-components';
import {Col, Modal, Form} from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { InputField, SelectField } from 'src/components/form'
import useForm, { FormValues } from './useForm';

type Props = {
    isShow: boolean;
    setIsShow: (v:boolean)=> void;
};

const NewEventForm:React.FC<Props> = ({isShow,setIsShow}) => {
    const { form, handleSubmit } = useForm();
    const { control } = form;
    const handleCancel = () => {
        setIsShow(false);
    };


    const optionsBlacklist = [
        { label: 'User', value: 'user' },
        { label: 'Token', value: 'token' }
    ];


    return (
        <Wrapper>
            <Modal
                title="Create New Item"
                visible={isShow}
                onOk={handleSubmit}
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label="Address" labelCol={{ span: 4 }}>
                        <Col md={24}>
                            <InputField<FormValues>
                                controller={{
                                    control,
                                    name: 'address'
                                }}
                                inputProps={{
                                    placeholder: 'Enter the address.',
                                }}
                            />
                        </Col>
                    </Form.Item>
                    <Form.Item label="Type" labelCol={{ span: 4 }}>x
                    <Col md={24}>
                        <SelectField<FormValues>
                            controller={{ name: 'type', control }}
                            selectProps={{
                                placeholder: 'Select the type.',
                                options: optionsBlacklist
                            }}
                        />
                    </Col>
                    </Form.Item>

                </Form>
            </Modal>
        </Wrapper>
    );
};

const Wrapper = styled.div`

`;

export default NewEventForm;
