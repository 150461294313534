import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import EventCreateContainer from 'src/containers/Event/Create';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { fetchResource } from 'src/useCase/challenge';
import { fetchResourceProject } from 'src/useCase/projects';

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();

  useEffect(() => {
    dispatch(fetchResource({take: '100'}));
  }, [query]);

  useEffect(() => {
    dispatch(fetchResourceProject({take: '100'}));
  }, [query]);

  return (
    <ContentWrapper title={'New Event'}>
      <EventCreateContainer />
    </ContentWrapper>
  );
};

export const PATH = '/events/create' as const;
export default Page;
