import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import backlistAction from 'src/store/domain/blacklist/list';
import appAction from 'src/store/ui/app';
import blacklistRequest from 'src/api/requests/blacklists';

interface FetchResource extends SearchParams.Users {}

const getParams = (args: any) => {
  const {
    take,
    page,
    address,
    type,
  } = args;

  const params: any = {
    take,
    page,
      address,
      type
  };

  return params;
};

export const fetchResource = createAsyncThunk<void, FetchResource>(
  'get_blacklist',
  async (args, { dispatch }) => {
    try {
      dispatch(backlistAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
      const res = await blacklistRequest.getList(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(backlistAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(backlistAction.updateLoading(false));
    } catch (err) {
      dispatch(backlistAction.updateLoading(false));
    }
  },
);

export const createBlacklist = createAsyncThunk<any, any>(
    'blacklist_create_new',
    async (game, { dispatch, rejectWithValue }) => {
      try {
        await blacklistRequest.create(game);

        dispatch(fetchResource({}));
      } catch (err: any) {
        rejectWithValue('Something went wrong!');
      }
    },
);

export const deleteBlacklist = createAsyncThunk<any, string>(
    'blacklist_delete',
    async (id, { dispatch, rejectWithValue }) => {
        try {
           const res = await blacklistRequest.deleteBlacklist(id);
            if (res){
                dispatch(fetchResource({}));
            }
        } catch (err: any) {
            rejectWithValue('Something went wrong!');
        }
    }
);
