import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import eventAction from 'src/store/domain/event/list';
import appAction from 'src/store/ui/app';
import eventRequest from 'src/api/requests/events';

interface FetchResource extends SearchParams.Users {}

const getParams = (args: any) => {
  const {
    take,
    page,
    keyword,
  } = args;

  const params: any = {
    take,
    page,
    keyword,
  };

  return params;
};

export const fetchResource = createAsyncThunk<void, FetchResource>(
  'get_events',
  async (args, { dispatch }) => {
    try {
      dispatch(eventAction.updateLoading(true));
      let params = _.omitBy(getParams(args), _.isEmpty)
      const res = await eventRequest.getList(params);
      const { data, meta } = res.data;

      if (data) {
        dispatch(eventAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.itemCount,
            current: meta.page,
          }),
        );
      }

      dispatch(eventAction.updateLoading(false));
    } catch (err) {
      dispatch(eventAction.updateLoading(false));
    }
  },
);

export const createEvent = createAsyncThunk<any, any>(
  'events_create_new',
  async (game, { dispatch, rejectWithValue }) => {
    try {
      await eventRequest.create(game);

      dispatch(fetchResource({}));
    } catch (err: any) {
      rejectWithValue('Something went wrong!');
    }
  },
);
