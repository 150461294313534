
const column = (t, dispatch) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Token 0',
    dataIndex: 'token0',
    key: 'type',
    render: (_, row) => {
      return (
        <div>
          <div><strong>Address:</strong> {row?.token0 || '-'}</div>
          <div><strong>Name:</strong> {row?.token0Entity?.name || '-'}</div>
          <div><strong>Symbol:</strong> {row?.token0Entity?.symbol || '-'}</div>
          <div><strong>Decimals:</strong>{row?.token0Entity?.decimals || '-'}</div>
        </div>
      )
    }
  },
  {
    title: 'Token 1',
    dataIndex: 'token1',
    key: 'type',
    render: (_, row) => {
      return (
        <div>
          <div><strong>Address:</strong> {row?.token1 || '-'}</div>
          <div><strong>Name:</strong> {row?.token1Entity?.name || '-'}</div>
          <div><strong>Symbol:</strong> {row?.token1Entity?.symbol || '-'}</div>
          <div><strong>Decimals:</strong>{row?.token1Entity?.decimals || '-'}</div>
        </div>
      )
    }
  },
  {
    title: 'Volume USD',
    dataIndex: 'volumeUSD',
    key: 'volumeUSD',
  },
];

export default column;

