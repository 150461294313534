import React from 'react';
import { Button, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const column = (t, dispatch,handleDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record: any) => (
        <Space>
          <Button danger
              type="primary"
              icon={<DeleteOutlined />}
                  onClick={() => handleDelete(record.id)}
          >
          </Button>
        </Space>
    ),
  },
];



export default column;
