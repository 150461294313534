import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import ProjectContainer from 'src/containers/Project';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom';
import {fetchResourceProject} from "../../useCase/projects";
import Search from "../../containers/Project/Search";

const Page: App.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<any>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchResourceProject(query));
  }, [query]);

  return (
    <ContentWrapper title={'Project Management'} extra={
      <React.Fragment>
        <Button type="primary" onClick={() => navigate('/projects/create')}>New Project</Button>
      </React.Fragment>
    }>
      <Search/>
      <ProjectContainer />
    </ContentWrapper>
  );
};

export const PATH = '/projects';
export default Page;
