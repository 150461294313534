import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { createEvent } from 'src/useCase/events';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

export type FormValues = {
  name: string,
  description: string,
  startTime: string,
  endTime: string,
  projectId: string,
  challengeKeys: string[],
};

const useCreateEventForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const defaultValues: FormValues = {
    name: '',
    description: '',
    startTime: '',
    endTime: '',
    projectId: '',
    challengeKeys: [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    startTime: Yup.string().required(),
    endTime: Yup.string().required(),
  });

  const handleSubmit = (callback) => {
    form.handleSubmit(async (data) => {
      dispatch(createEvent(data));
      message.success('Create event successfully');
      navigate('/events');
      await form.reset({ ...defaultValues });
      callback(false);
    })();
  };

  const resetForm = async () => {
    await form.reset({ ...defaultValues });
  };

  const form = useForm<FormValues>({
    defaultValues: defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  return {
    form,
    resetForm,
    handleSubmit,
  };
};

export default useCreateEventForm;
