import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Card, Col, Row, Button } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';

import {
    InputField, SelectField,

} from 'src/components/form';
import useSearch, { FormValues } from './useSearch';

const Search = () => {
  const { form, resetForm, handleSearch } = useSearch();
  const { control } = form;

    const optionsBlacklist = [
        { label: 'User', value: 'user' },
        { label: 'Token', value: 'token' }
    ];
  return (
      <Wrapper>
        <Card
            title={
              <Fragment>
                <FilterOutlined />
                <span>Search</span>
              </Fragment>
            }
        >
          <Row gutter={24}>
            <Col span={12}>
              <InputField<FormValues>
                  controller={{ name: 'address', control }}
                  inputProps={{
                    prefix: <SearchOutlined />,
                    allowClear: true,
                    placeholder: 'Address'
                  }}
              />
            </Col>
            <Col span={4}>
                <SelectField<FormValues>
                    controller={{ name: 'type', control }}
                    selectProps={{
                        options: optionsBlacklist,
                    }}
                />
            </Col>

            <Col span={4}>
              <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={handleSearch}
              >
                Search
              </Button>
            </Col>
            <Col span={4}>
              <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={resetForm}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Card>
      </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-radio-button-wrapper {
    margin-right: 6px;
  }
  .ant-form-item-label {
    text-align: left;
  }
`;

export default Search;
