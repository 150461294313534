import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { TFunction } from 'i18next';
import _ from 'lodash';

import authRequest, { Credentials } from 'src/api/requests/auth';
import meRequest from 'src/api/requests/me';
import { FormValues } from 'src/containers/SignIn/useLogin';
import { HTTP_CODE } from '../constants/app';
import appAction from 'src/store/ui/app';
import history from 'src/libs/history';
import LocalStorage from '../utils/LocalStorage';

const me = createAsyncThunk<any, string>(
  'me',
  async (token, { dispatch, rejectWithValue }) => {
    try {
      const res = await meRequest.me(token);

      dispatch(appAction.updateAuthInfo(res.data));
    } catch (err: any) {
      rejectWithValue(err.response.data);
    }
  },
);

export interface PostLogin {
  credentials: Credentials;
  meta: UseFormReturn<FormValues, any>;
  t: TFunction;
}

export const login = createAsyncThunk<any, PostLogin>(
  'login',
  async ({ credentials, meta, t }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRequest.login(credentials);

      const token = _.get(data, 'data.token.accessToken');

      LocalStorage.setToken(token);

      // await dispatch(me(token));

      history.push('/');
    } catch (err: any) {
      rejectWithValue(t('message.login_failed'));

      const status = err.response.status;

      if (status === HTTP_CODE.UNAUTHORIZED) {
        meta.setError('email', { type: 'validate' });
        meta.setError('password', { type: 'validate' });
      }
    }
  },
);
