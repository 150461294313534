import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { createBlacklist } from 'src/useCase/blacklist';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

export type FormValues = {
  address: string,
  type: string,
  blacklistKeys: string[],

};

const useCreateBlacklistForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const defaultValues: FormValues = {
    address: '',
    type: '',
    blacklistKeys: [],
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string().required(),
    type: Yup.string().required(),
  });

  const handleSubmit = () => {
    form.handleSubmit(async (data) => {
      dispatch(createBlacklist(data));
      message.success('Create blacklist successfully');
      navigate('/blacklists');
      await form.reset({ ...defaultValues });
    })();
  };

  const resetForm = async () => {
    await form.reset({ ...defaultValues });
  };

  const form = useForm<FormValues>({
    defaultValues: defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  return {
    form,
    resetForm,
    handleSubmit,
  };
};

export default useCreateBlacklistForm;
