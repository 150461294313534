import React, { useMemo } from 'react';
import { I18n } from 'src/libs/i18n';
import { ColumnType } from 'antd/es/table';
import { RadioChangeEvent } from 'antd';
import dayjs from 'dayjs';

import customDayjs from 'src/libs/day';
import { SortIcon } from 'src/components/common';
import { DATE_TIME_FORMAT, DATE_TIME_HOUR } from '../constants/app';
import { SITE_ROLE } from 'src/enum/common';

export type GetSortProps<T> = {
  sortInfo?: App.SortInfo<T>;
  field: keyof T;
  title: string;
};

export const getSortProps = <T>({
  sortInfo,
  field,
  title,
}: GetSortProps<T>) => {
  if (!sortInfo) return {};

  return {
    sortOrder: field === sortInfo.sort_by ? sortInfo.order_by : null,
    sorter: true,
    dataIndex: field,
    showSorterTooltip: {
      title: I18n.t('message.click_to_sort'),
      placement: 'topRight',
    },
    title({ sortColumns }) {
      const sortColumn = sortColumns?.find((col) => Boolean(col.order));

      return React.createElement(SortIcon, {
        column: sortColumn?.column,
        order: sortColumn?.order,
        title,
        field: String(field),
      });
    },
  } as ColumnType<T>;
};

export const formatDate = (
  day?: string | null,
  options?: { format?: string; defaultValue?: string },
) => {
  const { defaultValue = '-', format = DATE_TIME_HOUR } = options || {};

  if (!day) return defaultValue;

  if (customDayjs(day).isValid()) {
    return customDayjs(day).format(format);
  }

  return defaultValue;
};

export const onChangeQuickPeriod = (
  e: RadioChangeEvent,
  setValue: Function,
) => {
  const value = e.target.value;
  setValue('quick_period_search', value);
  const setFromDate = setValue.bind(null, 'from_date');
  const setToDate = setValue.bind(null, 'to_date');
  setValue('to_date', dayjs().format(DATE_TIME_FORMAT));

  switch (value) {
    case 'today':
      setFromDate(dayjs().format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));
      break;
    case 'yesterday':
      setFromDate(dayjs().subtract(1, 'day').format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));
      break;
    case 'week':
      setFromDate(dayjs().subtract(1, 'week').format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));

      break;
    case 'month':
      setFromDate(dayjs().subtract(1, 'month').format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));

      break;
    default:
      break;
  }
};
