import React, {useState} from 'react';
import styled from 'styled-components';
import {Col, Row, Button, AutoComplete} from 'antd';
import { InputField, TextAreaField, DatePickerField, SelectField } from 'src/components/form'
import useForm, { FormValues } from './useForm';
import { useAppSelector } from 'src/store';
import { useNavigate } from 'react-router-dom';
import AutoSuggestField from "../../../components/form/AutoSuggestField";

const NewEventForm = () => {
  const navigate = useNavigate();
  const { form, handleSubmit } = useForm();
  const { control } = form;
  const challenges = useAppSelector((state) => state.domain.challenge.list.entities);
    const optionsChallenges = challenges.map((challenge: any) => ({
        label: challenge.name,
        value: challenge.key,
    }));

  return (
    <Wrapper>
      <Row gutter={24} justify='space-between' className="submit-btn">
        <Button onClick={() => navigate('/events')}>Back To List</Button>
        <Button type='primary' onClick={handleSubmit}>Submit Event</Button>
      </Row>
      <Row gutter={24}>
          <Col md={24}>
              <AutoSuggestField<FormValues>
                  controller={{
                      control,
                      name: 'projectId',
                  }}
                  label={'Project'}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}

              />
          </Col>
        <Col md={24}>
          <InputField<FormValues>
            controller={{
              control,
              name: 'name',
            }}
            inputProps={{
              placeholder: 'Enter the event name.',
            }}
            label={'Event Name'}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          />
        </Col>
        <Col md={24}>
          <TextAreaField<FormValues>
            controller={{
              control,
              name: 'description',
            }}
            label={'Event Description'}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          />
        </Col>

        <Col md={24}>
          <DatePickerField<FormValues>
            controller={{
              control,
              name: 'startTime'
            }}
            datePickerProps={{
              showTime: true,
              format: 'YYYY-MM-DD HH:mm:ss',
            }}
            label="Start Time"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          />
        </Col>
        <Col md={24}>
          <DatePickerField<FormValues>
            controller={{
              control,
              name: 'endTime'
            }}
            datePickerProps={{
              showTime: true,
              format: 'YYYY-MM-DD HH:mm:ss',
            }}
            label="End Time"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          />
        </Col>

        <Col md={24}>
          <SelectField<FormValues>
            label={'Event Challenge'}
            controller={{ name: 'challengeKeys', control }}
            selectProps={{
              mode:"tags",
              options: optionsChallenges
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-radio-button-wrapper {
    margin-right: 6px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .submit-btn {
    margin-bottom: 30px;
    padding: 10px;
  }
`;

export default NewEventForm;
