import React from 'react';

import { IRouter } from 'src/interfaces/route';
import { PATH as ONCHAIN_LOGS_PATH } from 'src/pages/onchain_logs';
import { PATH as MINT_LOGS_PATH } from 'src/pages/mint';
import { PATH as SWAP_LOGS_PATH } from 'src/pages/swap';
import { PATH as BURN_LOGS_PATH } from 'src/pages/burn';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: ONCHAIN_LOGS_PATH,
        element: loadable(() => import('src/pages/onchain_logs')),
        title: 'On Chain Logs Management',
      },
      {
        path: MINT_LOGS_PATH,
        element: loadable(() => import('src/pages/mint')),
        title: 'Mint Logs Management',
      },
      {
        path: SWAP_LOGS_PATH,
        element: loadable(() => import('src/pages/swap')),
        title: 'Mint Logs Management',
      },
      {
        path: BURN_LOGS_PATH,
        element: loadable(() => import('src/pages/burn')),
        title: 'Mint Logs Management',
      },
    ],
  },
];

export default Route;
