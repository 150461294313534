import React, { useState } from 'react';
import { Select, Space, Tag, TagProps } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import useQueryUrl from 'src/hook/useQueryUrl';
import { PER, SORT_ORDER } from 'src/constants/app';
import { useAppSelector } from 'src/store';

type UseTable = {
  hasSelect: boolean;
};

const useTable = <T extends { id: string | number }>(
  { hasSelect }: UseTable = { hasSelect: true },
) => {
  const { t } = useTranslation();
  const { query, setSearchQuery } = useQueryUrl();
  const [rowKeysSelected, setRowKeysSelected] = useState<React.Key[]>([]);
  const pagination = useAppSelector((state) => state.ui.app.pagination);

  const onChangePageSize = (take) => {
    setSearchQuery(qs.stringify({ ...query, take: String(take), page: 1 }));
  };

  const paginationProps: TablePaginationConfig = {
    ...pagination,
    pageSize: query.take ? Number(query.take) : PER.DEFAULT,
    showSizeChanger: false,
    showTotal(total) {
      return (
        <Space>
          {`${total.toLocaleString()} ${t('label.items')}`}
          <Select
            size="small"
            value={query.take || String(PER.DEFAULT)}
            onChange={onChangePageSize}
          >
            <Select.Option value={'10'}>10 / {t('page')}</Select.Option>
            <Select.Option value={'20'}>20 / {t('page')}</Select.Option>
            <Select.Option value={'50'}>50 / {t('page')}</Select.Option>
            <Select.Option value={'100'}>100 / {t('page')}</Select.Option>
          </Select>
        </Space>
      );
    },
  };

  const rowSelection: TableProps<T>['rowSelection'] = {
    type: 'checkbox',
    onSelect(data, checked) {
      if (checked) {
        setRowKeysSelected([...rowKeysSelected, data.id]);
        return;
      }

      const rowFiltered = rowKeysSelected.filter((row) => row !== data.id);
      setRowKeysSelected(rowFiltered);
    },
    onSelectAll(checked, changed, selected) {
      const listIds = selected.map((d) => d.id) as React.Key[];
      if (checked) {
        setRowKeysSelected([...rowKeysSelected, ...listIds]);
        return;
      }

      const rowFiltered = rowKeysSelected.filter(
        (key) => !listIds.includes(key),
      );
      setRowKeysSelected(rowFiltered);
    },
    selectedRowKeys: rowKeysSelected,
  };

  const Selected = (props: TagProps) => {
    if (rowKeysSelected.length) {
      return React.createElement(
        Tag,
        {
          closable: true,
          onClose: () => setRowKeysSelected([]),
          color: 'gold',
          style: {
            fontSize: 14,
            padding: '4px 8px',
          },
          ...props,
        },
        props.children,
      );
    }

    return null;
  };

  const onTableChange: TableProps<T>['onChange'] = (p, f, sorter) => {
    if (Array.isArray(sorter)) return;

    const sortInfo: App.SortInfo<T> = {
      sort_by: sorter.column ? sorter.field : undefined,
      order_by: sorter.order,
    };

    setSearchQuery(
      qs.stringify({
        ...query,
        ...sortInfo,
        page: p.current,
      }),
    );
  };

  const sortInfo: App.SortInfo<T> = {
    sort_by: query.sort_by,
    order_by: query.order_by,
  };

  return {
    pagination: paginationProps,
    rowSelection: hasSelect ? rowSelection : undefined,
    Selected,
    setRowKeysSelected,
    rowSelected: rowKeysSelected,
    sortDirections: [SORT_ORDER.DESC, SORT_ORDER.ASC] as any,
    onChange: onTableChange,
    sortInfo,
  };
};

export default useTable;
