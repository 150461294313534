import React from 'react';
import { formatDate } from 'src/utils/Helpers'

const column = (t, dispatch) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'userId',
    render: (_, row) => {
      return (
        <div>
          <span>{row?.id || '-'}</span>
        </div>
      );
    }
  },

  {
    title: 'Wallet Address',
    dataIndex: 'address',
    key: 'address',
    render: (_, row) => {
      return (
        <div>
          <span>{row?.address || '-'}</span>
        </div>
      );
    }
  },
  {
    title: 'Current Point',
    key: 'pointBalance',

    render: (_, row) => {
      return <span>{Number(row.pointBalance).toLocaleString() || '-'}</span>;
    },
  },
  {
    title: 'Created At',
    key: 'createdAt',

    render: (_, row) => {
      return <span>{formatDate(row?.createdAt) || '-'}</span>;
    },
  },
  {
    title: 'Updated At',
    key: 'updatedAt',

    render: (_, row) => {
      return <span>{formatDate(row?.updatedAt) || '-'}</span>;
    },
  },
];

export default column;
