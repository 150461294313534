import HTTPRequest from '../index';

const apiPathOnchain = '/admin/on-chain-logs';
const apiPathSwap = '/admin/swap';
const apiPathBurn = '/admin/burn';
const apiPathMint = '/admin/mint';

class Transaction extends HTTPRequest {
 listChainLogs = (params) => {
   return this.get(`${apiPathOnchain}`, {params});
 }

  listSwaps = (params) => {
    return this.get(`${apiPathSwap}`, {params});
  }

  listMints = (params) => {
    return this.get(`${apiPathMint}`, {params});
  }

  listBurns = (params) => {
    return this.get(`${apiPathBurn}`, params);
  }
}

export default new Transaction();
