import React from 'react';

import { IRouter } from 'src/interfaces/route';
import { PATH } from 'src/pages/user';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';

const DashboardRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/user')),
        title: 'User Management',
      },
    ],
  },
];

export default DashboardRoute;
