import React from 'react';
import { Layout } from 'antd';
import {  Outlet } from 'react-router-dom';
import _ from 'lodash';
import AppHeader from './Header';
import AppSider from './Sider';
import { useAppSelector, useAppDispatch } from 'src/store';
import appActions from 'src/store/ui/app';
import { PropsWithChildrenOnly } from 'src/interfaces/react';
import LocalStorage from 'src/utils/LocalStorage';
import { PATH } from 'src/pages/auth'
import { Navigate } from 'react-router-dom'

const MainLayout: React.FC<PropsWithChildrenOnly> = ({ children }) => {
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector((state) => state.ui.app.collapsed);
  const toggleCollapse = () => dispatch(appActions.updateCollapsed(!collapsed));

  const accessToken = LocalStorage.accessToken;

  if (_.isEmpty(accessToken)) {
    return <Navigate to={PATH.SIGN_IN} replace />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppSider collapsed={collapsed} />
      <Layout>
        <AppHeader collapsed={collapsed} toggleCollapse={toggleCollapse} />
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
