import React from 'react';
import { ConfigProvider } from 'antd';
import en_US from 'antd/es/locale/en_US';
import { useRoutes } from 'react-router-dom';

import routeConfig from 'src/routes';
// import Notification from './containers/Notification';

function App() {
  const routes = useRoutes(routeConfig);

  return (
    <ConfigProvider
      locale={en_US}
      componentSize="middle"
    >
      {routes}
      {/*<Notification />*/}
    </ConfigProvider>
  );
}

export default App;
