import React from 'react';
import { Layout, Menu, Dropdown, Avatar, Space } from 'antd';
import {
  LogoutOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { get } from 'lodash';

import { APP_HEADER_HEIGHT } from 'src/constants/app';
import LocalStorage from 'src/utils/LocalStorage';
import history from 'src/libs/history';

const { Header } = Layout;

type Props = {
  collapsed: boolean;
  toggleCollapse: () => void;
};

const AppHeader: React.FC<Props> = ({ collapsed, toggleCollapse }) => {
  const getUserName = () => {
    const me = LocalStorage.authInfo;
    return get(me, 'username') || 'Admin';
  };

  const logout = () => {
    LocalStorage.removeInfo();
    history.push('/login');
  };

  const overlay = (
    <Menu>
      {/*<Menu.Item key="0" icon={<SettingOutlined />}>*/}
      {/*  <Link to="/profile">프로필</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="1" onClick={logout} icon={<LogoutOutlined />}>
       Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrapper>
      <Header>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            onClick: toggleCollapse,
            style: { fontSize: 20 },
          },
        )}
        <Space>
          {/*<SwitchLang />*/}
          <Dropdown overlay={overlay} placement="bottomRight">
            <span className="drop-down">
              <Avatar icon={<UserOutlined />} />
              <span className="header_text">{getUserName()}</span>
            </span>
          </Dropdown>
        </Space>
      </Header>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-layout-header {
    height: ${APP_HEADER_HEIGHT}px;
    background: #fff;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .drop-down {
    display: flex;
    align-items: center;
    cursor: pointer;
    .header_text {
      margin-left: 8px;
    }
  }
`;

export default AppHeader;
